import React from "react";
import { isEmpty } from "lodash";

import { EButtonSize } from "@components/Button/types";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import CtasCollection from "@components/ContentfulComponents/ComponentTextAssetAndCtas/CtasCollection/CtasCollection.component";
import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import AssetWithLink from "@components/ContentfulComponents/ComponentTextAssetAndCtas/TextImageCta/AssetWithLink/AssetWithLink.component";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";

import { ITextAssetAndCtasCflData } from "../type";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/TextImageCta/text-image-cta.module.scss";
export interface ITextImageCta
	extends Pick<
		ITextAssetAndCtasCflData,
		"asset" | "assetLink" | "title" | "richTextContent" | "openLinksInANewTab" | "ctasCollection"
	> {
	absoluteUrl?: string;
}

const TextImageCta = ({
	asset,
	assetLink,
	title,
	richTextContent,
	openLinksInANewTab,
	ctasCollection,
	absoluteUrl,
}: ITextImageCta) => {
	if (!asset || !title) return null;

	return (
		<div className={styles.textImageCtaWrapper} data-testid="TextImageCta_wrapper">
			{assetLink?.url ? (
				<AssetWithLink
					asset={asset}
					imageSlot={{ mobile: EImageSlot.Full, desktop: EImageSlot.Third }}
					assetLink={assetLink}
					absoluteUrl={absoluteUrl}
				/>
			) : (
				<Asset
					data={asset}
					imageSlot={{ mobile: EImageSlot.Full, desktop: EImageSlot.Third }}
					loading
				/>
			)}
			<div className={styles.textImageCtaText}>
				<h2 data-testid="TextImageCta_title">{title}</h2>
				{richTextContent &&
					!isEmpty(richTextContent.json) &&
					renderRichTextWithLinks({ richTextDocument: richTextContent.json, openLinksInANewTab })}
			</div>
			<div className={styles.textImageCtaCtas}>
				<CtasCollection ctasCollection={ctasCollection} size={EButtonSize.Sm} />
			</div>
		</div>
	);
};

export default TextImageCta;
