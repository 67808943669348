import Asset from "@components/ContentfulComponents/ComponentAsset/Asset.component";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { EImageSlot } from "@components/CustomImage/custom-images.types";
import { convertRelOptions, convertTargetOptions } from "@shared/contentful/contentful.utils";
import { removeLeadingSlash } from "@utils/url.utils";
import React from "react";

interface IAssetWithLink {
	asset?: IAssetCflData;
	assetLink?: ILinkCflData;
	imageSlot?: { mobile: EImageSlot | string; desktop: EImageSlot | string };
	absoluteUrl?: string;
}

const AssetWithLink = ({ asset, assetLink, imageSlot, absoluteUrl }: IAssetWithLink) => {
	if (!asset || !assetLink) return null;

	const assetLinkRel = convertRelOptions(assetLink.relOptions);
	const assetLinkTarget = convertTargetOptions(assetLink.targetOptions);

	return (
		<a
			href={`${absoluteUrl}/${removeLeadingSlash(assetLink.url)}`}
			target={assetLinkTarget}
			rel={assetLinkRel ?? ""}
			data-testid="AssetWithLink_image-with-link"
		>
			<Asset data={asset} imageSlot={imageSlot} loading />
		</a>
	);
};

export default AssetWithLink;
